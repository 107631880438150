.checkbox_text {
  color: #afafaf;
  font-size: 14px;
  margin-left: 13px;
}

.radio_text {
  color: #afafaf;
  font-size: 14px;
  margin: 0;
}

/* Hide the default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  background-color: #ccc;
  transition: all 0.3s;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: rgba(46, 187, 85, 1);
  animation: pop 0.3s;
  animation-direction: alternate;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  width: 0.25em;
  height: 0.5em;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.agreementText {
  color: #afafaf;
  opacity: 0.7;
  cursor: pointer;
  text-decoration: underline;
}

.agreementText:hover {
  color: rgba(46, 187, 85, 1);
}

.infoImage {
  width: 17.5px;
  height: 17.5px;
  object-fit: cover;
  margin-bottom: 10px;
  opacity: 0.8;
  margin-left: 20px;
}

.infoImage:hover {
  opacity: 1;
}
