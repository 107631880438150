.main_layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.page_layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 200px);
  height: 100%;
  padding: 40px;
}

.main_feed_section {
  display: flex;
  flex-direction: column;
  width: 64%;
  gap: 15px;
  box-sizing: border-box;
}

.feed {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  gap: 15px;
  overflow: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
  padding: 2px;
}

.page_divider {
  width: 100%;
  min-height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.match_section {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 33%;
  height: 500px;
  /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5); */
  border-radius: 10px;
  box-sizing: border-box;
  padding: 12.5px;
}

.right_sidebar {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  width: 370px;
  box-sizing: border-box;
  padding: 25px;
  gap: 25px;
}

.resources_sidebar {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4); */
  width: 370px;
  box-sizing: border-box;
  padding: 25px;
  gap: 25px;
}
/* #2C4E85 */
/* rgba(44, 78, 133, 1) */
.new_post_prompt {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  /* height: 50px; */
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.025);
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  box-sizing: border-box;
  padding: 15px;
  border-radius: 0px;
  gap: 15px;
  border: 1px solid rgba(44, 78, 133, 0);
}

.new_post_prompt_small {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  /* height: 50px; */
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.025);
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  box-sizing: border-box;
  padding: 15px;
  border-radius: 0px;
  border: 1px solid rgba(44, 78, 133, 0);
}

.inputActive {
  border: 1px solid var(--primary_color_quarter);
}

.new_post_profile_image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.posted_image {
  height: 200px;
  width: 100%;
  object-fit: cover;

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid rgba(200, 0, 0, 0);
}

.posted_image:hover {
  opacity: 0.95;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(200, 0, 0, 0.7);
}

.user_post {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  /* min-height: 125px; */
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); */
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  box-sizing: border-box;
  padding: 15px;
  border-radius: 5px;

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
}

.post_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.post_bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 27.5px;
  border-radius: 3px;
  background-color: rgba(44, 78, 133, 0.3);
  border: 1px solid var(--primary_color);
  color: var(--primary_color);
  font-size: 14px;
  cursor: pointer;

  border-color: 1px solid var(--primary_color);
  color: var(--primary_color);
  background-color: white;
}

.post_button:hover {
  background-color: rgba(44, 78, 133, 0.3);
  border: 1px solid var(--primary_color);
  color: var(--primary_color);
}

.post_button_disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 27.5px;
  border-radius: 3px;
  background-color: rgba(44, 78, 133, 0.3);
  border: 1px solid var(--primary_color);
  color: var(--primary_color);
  font-size: 14px;
  cursor: pointer;

  border-color: 1px solid var(--primary_color);
  color: var(--primary_color);
  background-color: white;

  opacity: 0.3;
}

.post_top_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.post_prompt_left {
  display: flex;
  flex-direction: row;
  align-items: start;
  flex: 1;
}

.post_top_right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile_image {
  width: 37.5px;
  height: 37.5px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.profile_image:hover {
  box-shadow: 0px 0px 3.5px rgba(0, 0, 0, 0.35);
}

.new_post_text_bubble {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-left: 12.5px;
  /* background-color: rgba(0, 0, 0, 0.05); */
  flex: 1;
  height: 55px;
  border-radius: 0px;
  box-sizing: border-box;
}

.post_text {
  flex: 1;
  width: 100%;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 10px;
  padding-top: 7.5px;
  background-color: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.post_top_text {
  display: flex;
  flex-direction: column;
  gap: 1.5px;
  align-items: start;
  justify-content: center;
  margin-left: 12.5px;
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
}

.new_post_divider {
  background-color: rgba(0, 0, 0, 0.05);
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.post_name {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}

.post_name:hover {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: rgba(44, 78, 133, 1);
  text-decoration: underline;
}

.post_subtitle {
  margin: 0;
  font-size: 13px;
  font-weight: 200;
  opacity: 0.8;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  margin-bottom: 15px;
}

.post_middle {
}

.post_bottom {
  display: flex;
  align-items: end;
  flex-direction: row;
  margin: 0;
  gap: 20px;
}

.action_text {
  margin: 0;
  color: black;
  opacity: 0.5;
  font-size: 14px;
  cursor: pointer;
}

.action_text:hover {
  opacity: 0.85;
}

.post_caption {
  text-align: start;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.9;
}

.post_image {
  height: 200px;
  width: 100%;
  object-fit: cover;
  margin-top: 15px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.post_image:hover {
  opacity: 0.95;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
}

.user_image_post {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  min-height: 250px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); */
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.section_title {
  color: white;
  margin: 0;
  text-align: start;
  font-size: 20px;
  font-weight: 300;
}

.resource_tile {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 1);
  margin-top: 15px;
}

.section_title_match {
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
  text-align: start;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 7.5px;
}

.connect_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 22px;
  border-radius: 5px;
  border: 1px solid rgba(44, 78, 133, 0.3);
  cursor: pointer;
  color: rgba(44, 78, 133, 1);
  margin: 0;
  font-size: 200;
  font-size: 12px;
  background-color: rgba(44, 78, 133, 0.05);
  color: rgba(44, 78, 133, 1);
}

.connect_button:hover {
  background-color: white;
  border: 1px solid rgba(44, 78, 133, 1);
  color: rgba(44, 78, 133, 1);
}

.profile_match_image {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.profile_match_image:hover {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.match_text {
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: start;
  margin-left: 12.5px;
}

.match_name {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  text-align: start;
}

.match_name:hover {
  text-decoration: underline;
}

.match_subtitle {
  margin: 0;
  font-size: 12px;
  font-weight: 200;
  opacity: 0.8;
}

.suggested_match {
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left_side_bottom {
  display: flex;
  gap: 20px;
}

.post_action_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  opacity: 0.5;
  cursor: pointer;
}

.post_action_item:hover {
  opacity: 1;
}

.post_action_item_icon {
  width: 25px;
}

.post_actions {
  display: flex;
  gap: 25px;
}

.post_action_item_text {
  color: black;
  margin: 0;
  font-size: 13px;
}

.post_timestamp {
  color: black;
  margin: 0;
  font-size: 12px;
  opacity: 0.6;
}

.more_button {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
  box-sizing: border-box;
}

.more_button:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

.more_dot {
  background-color: rgba(0, 0, 0, 0.5);
  width: 2.2px;
  height: 2.2px;
  border-radius: 50%;
}

.menu {
  position: absolute;
  right: 0px;
  top: 35px;
  width: 150px;
  /* height: 200px; */
  background-color: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  /* border: 1px solid black; */
  z-index: 1;
}

.menu_item {
  display: flex;
  align-items: center;
  height: 50px;
  user-select: none;
}

.menu_item:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.menu_item_text {
  color: black;
  opacity: 0.65;
  margin: 0;
  margin-left: 10px;
  user-select: none;
}

.more_layout {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlayImage {
  max-width: 90%;
  max-height: 90%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.connect_text {
  color: black;
  opacity: 0.6;
  margin: 0;
  text-align: start;
  font-size: 11px;
  font-weight: 400;
  user-select: none;
}

.connect_button_background {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 20px; */
  box-sizing: border-box;
  padding: 4px 10px;
  gap: 10px;
  /* width: 35px; */
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
}

.connect_button_background:hover {
  background-color: rgba(44, 78, 133, 0.1);
}

.connect_icon {
  width: 12px;
  height: 12px;
  opacity: 0.7;
  user-select: none;
}

.sidebar_title {
  color: black;
  margin: 0;
  text-align: start;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0px;
}

.promotion_tile {
  width: 100%;
  box-shadow: 0px 0px 2.75px rgba(0, 0, 0, 0.3);
  border-radius: 1px;
  box-sizing: border-box;
  padding: 15px;
  background-color: var(--primary_color_light);
}

.promotion_image {
  width: 100%;
  object-fit: cover;
}

.promotion_top_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.promotion_title_text {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 15px;
  gap: 1px;
}

.no_image_title_text {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2px;
}

.promotion_title {
  margin: 0;
  text-align: start;
  font-size: 15px;
}

.promotion_subtitle {
  margin: 0;
  text-align: start;
  font-weight: 200;
  font-size: 14px;
  color: rgba(44, 78, 133, 1);
}

.promotion_description {
  text-align: start;
  color: black;
  font-size: 13px;
  font-weight: 300;
  opacity: 0.7;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 15px;
}

.promotion_controls {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;
  justify-content: space-between;
}

.learn_more_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgba(44, 78, 133, 1);
  font-size: 14px;
  font-weight: 300;
  color: rgba(44, 78, 133, 1);
  cursor: pointer;
}

.register_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid rgba(44, 78, 133, 1);
  font-size: 14px;
  font-weight: 300;
  color: rgba(44, 78, 133, 1);
  background-color: rgba(44, 78, 133, 0.15);
  cursor: pointer;
}

.check_out_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid rgba(44, 78, 133, 1);
  font-size: 14px;
  font-weight: 300;
  color: rgba(44, 78, 133, 1);
  background-color: rgba(44, 78, 133, 0.15);
  cursor: pointer;
}

.title_favorited_resources {
  color: black;
  margin: 0;
  text-align: start;
  font-size: 18px;
  font-weight: 300;
  /* margin-bottom: 15px; */
}

.connect_button_background_sent {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 20px; */
  box-sizing: border-box;
  padding: 4px 10px;
  gap: 10px;
  /* width: 35px; */
  height: 25px;
  border: 1px solid rgba(44, 78, 133, 1);
  border-radius: 30px;
  cursor: pointer;
  background-color: rgba(44, 78, 133, 0.2);
  color: rgba(44, 78, 133, 1);
}

.sent_text {
  color: rgba(44, 78, 133, 1);
  margin: 0;
  text-align: start;
  font-size: 11px;
  font-weight: 400;
  user-select: none;
}

.feed_spacer {
  min-height: 300px;
}
.announcement_scroll {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.timestamp_announcement {
  color: black;
  margin: 0;
  align-self: end;
  text-align: end;
  font-size: 12px;
  font-weight: 200;
}

.subscribed_programs {
  color: black;
  margin: 0;
  text-align: start;
  font-size: 16px;
  font-weight: 300;
  /* margin-bottom: 15px; */
}