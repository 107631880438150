.circularLoader {
  height: 70%;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.circularLoaderCircle {
  fill: none;
  stroke: rgba(255, 255, 255, 1);
  stroke-width: 1;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}
