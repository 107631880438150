.main_layout {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 50px;
  padding-bottom: 0;
  /* height: 100%; */
  /* width: 100%; */
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.page_controls_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
}

.page_title_blue {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: rgba(0, 61, 130, 1);
  margin: 0;
}

.page_title_margin {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  margin-bottom: 10px;
}

.divider {
  background-color: rgba(0, 61, 130, 0.2);
  height: 1px;
  width: 100%;
  margin-top: 15px;
}

.edit_guidelinees_modal {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  width: 100%;
  overflow-x: visible;
}

.guideline_layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  padding-bottom: 10px;
}

.modal_footer {
  display: flex;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  /* margin-top: 30px; */
}

.cancel_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.65);
  margin-right: 15px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.cancel_button:hover {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.8);
  color: rgba(0, 0, 0, 0.8);
}

.new_version {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 170px;
  margin-right: 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(0, 61, 130, 0);
  background-color: rgba(0, 61, 130, 1);
  color: white;
}

.new_version:hover {
  background-color: white;
  color: rgba(0, 61, 130, 1);
  border: 1px solid rgba(0, 61, 130, 1);
}

.save_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(0, 61, 130, 0);
  background-color: rgba(0, 61, 130, 1);
  color: white;
}

.save_button:hover {
  background-color: white;
  color: rgba(0, 61, 130, 1);
  border: 1px solid rgba(0, 61, 130, 1);
}

.guideline_controls {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.1); */
  height: 40px;
  margin-bottom: 10px;
  flex: 0 0 auto;
}

.guidline_modal_top {
  display: flex;
  flex-direction: column;
}

.guideline_search {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  height: 45px;
  flex-grow: 1;
  margin-right: 10px;
  border-radius: 5px;
}

.search_icon {
  width: 25px;
  height: 25px;
  opacity: 0.5;
  margin-left: 15px;
}

.search_divider {
  width: 1px;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  opacity: 0.75;
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.search_input {
  color: black;
  opacity: 0.5;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 15px;
}

.guideline_add {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 61, 130, 1);
  width: 45px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}

.add_guideline {
  width: 20px;
  height: 20px;
  opacity: 1;
}

.guideline_add:hover {
  background-color: rgba(0, 61, 130, 0.75);
}

.global_guideline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.15); */
  background-color: rgba(0, 61, 130, 1);
  min-height: 90px;
  flex-shrink: 0;
  width: 100%;
  margin-top: 15px;
  border-radius: 5px;
  flex: 0 0 auto;
}

.global_title {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.global_edit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 60px;
  /* background-color: rgba(0, 0, 0, 0.1); */
  background-color: white;
  color: rgba(0, 61, 130, 1);
  border-radius: 2.5px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0);
}

.global_edit_button:hover {
  background-color: transparent;
  color: rgba(0, 61, 130, 1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 1);
}

.spacer {
  min-height: 125px;
}

.edit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 60px;
  /* background-color: rgba(0, 61, 130, 1); */
  border: 1px solid rgba(0, 61, 130, 1);
  color: rgba(0, 61, 130, 1);
  border-radius: 2.5px;
  cursor: pointer;
}
.edit_button:hover {
  background-color: rgba(0, 61, 130, 1);
  color: white;
}

.edit_guideline_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.back_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.back_button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  height: 45px;
  width: 100px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  color: white;
  box-sizing: border-box;
}

.back_button:hover {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.8);
  color: rgba(0, 0, 0, 0.8);
}

.temp_controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
  border: 1px solid rgba(0, 61, 130, 1);
  height: 45px;
  width: 50%;
  border-radius: 5px;
  user-select: none;
}

.version_title_info {
  padding-left: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 55px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid rgba(0, 61, 130, 1);
  box-sizing: border-box;
}

.version_title_word {
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;

  font-weight: 500;
  margin-right: 5px;
}

.version_title {
  background-color: transparent;
  flex-grow: 1;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: lighter;
  color: rgba(0, 61, 130, 1);
}

.edit_guidline_info {
  padding-left: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid rgba(0, 61, 130, 1);
  box-sizing: border-box;
}

.edit_guideline_text {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.edit_guidline {
  display: flex;
  align-items: start;
  justify-content: start;
  width: 100%;
  flex-grow: 1;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 61, 130, 1);
  box-sizing: border-box;
  padding: 15px;
}

.edit_guideline_text_area {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  /* padding-top: 10px;
  padding-left: 10px; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  overflow-y: auto;
}

.back_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.back_text {
}

.global_guidelines_dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.1); */

  border: 1px solid rgba(0, 61, 130, 1);
  height: 45px;
  flex-grow: 1;
  /* width: 50%; */
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  padding-left: 10px;
  padding-right: 12px;
  box-sizing: border-box;
}

.global_guidelines_dropdown:hover {
  background-color: rgba(0, 61, 130, 0.1);
}

.dropdown_menu {
  position: absolute;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  top: 110%; /* Adjusted from 0 to 100% to appear below the container */
  left: 0;
  width: 100%; /* Optionally set width to match the parent div */
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1); /* Optional: added shadow for better visibility */
  max-height: 250px;
  overflow-y: auto;
}

.dropdown_menu_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 50px;

  border-bottom: 1px solid rgba(0, 61, 130, 0.1);
}

.dropdown_menu_item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown_menu li {
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
}

.dropdown_menu li:hover {
  background-color: #f0f0f0;
}

.dropdown_icon {
  width: 20px;
  height: 20px;
  user-select: none;
}

.dropdown_icon_blue {
  width: 10px;
  /* height: 10px; */
  user-select: none;
  object-fit: cover;
}

.set_as_live {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  border: 1px solid rgba(0, 61, 130, 1);
  height: 45px;
  width: 150px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  padding-left: 10px;
  padding-right: 12px;
  box-sizing: border-box;
}

.last_updated {
  width: 500px;
  margin: 0;
  text-align: start;
  font-size: 12px;
  opacity: 0.7;
  align-items: start;
}

.dropdown_menu {
  position: absolute;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  top: 110%;
  left: 0;
  width: calc(100%);
  box-shadow: 0px 0px 5px rgba(164, 155, 155, 0.5);
  /* background-color: rgba(0, 61, 130, 1); */
  max-height: 350px;
  overflow-y: auto;

  border: 1px solid rgba(0, 61, 130, 0.5);
}

.dropdown_menu_item {
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 50px;
  box-sizing: border-box;
  padding-left: 15px;
  border-bottom: 1px solid rgba(0, 61, 130, 0.1);
  color: rgba(0, 61, 130, 1);
}

.dropdown_menu_item:hover {
  background-color: rgba(0, 61, 130, 0.1);
}

.dropdown_menu li {
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
}

.dropdown_menu li:hover {
  background-color: #f0f0f0;
}

.fine_tune_model {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  background-color: white;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); */
  min-height: 185px;
  width: 100%;
  /* margin-top: 15px; */
  border-radius: 5px;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(0, 61, 130, 0.2);
  /* border: 1px solid rgba(0, 61, 130, 1); */
}

.model_information_top {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.model_information_bottom {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  width: 100%;
}

.model_information {
  display: flex;
  flex-direction: row;
  gap: 150px;
}

.model_info_section {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.parmater_info_section {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.training_data {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px 15px;
  border-radius: 2.5px;
  color: rgba(0, 61, 130, 1);
  cursor: pointer;
  font-size: 14px;
}

.training_data:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 61, 130, 1);
}

.model_section_title {
  margin: 0;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
}

.model_section_text {
  margin: 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 5px;
}

.model_created_at {
  margin: 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.status_box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 131, 10, 0.05);
  padding: 10px 15px;
  color: #00830a;
  border-radius: 5px;
  font-size: 14px;
}

.view_information_buttons {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.form_field_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* margin-top: 25px; */
  width: 100%;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin-bottom: 35px;
}

.section_divider {
  background-color: rgba(0, 61, 130, 0.2);
  height: 1px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.form_field_container_margin {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 15px;
  width: 100%;
}

.title_area_create {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.back_button_small {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  color: white;
  box-sizing: border-box;
  margin-right: 15px;
}

.back_button_small:hover {
  background-color: rgba(0, 0, 0, 0.05);
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
}

.back_icon_opacity {
  width: 20px;
  height: 20px;
  object-fit: cover;
  opacity: 0.7;
}

.data_set {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  background-color: white;
  height: 170px;
  width: 100%;
  /* margin-top: 15px; */
  border-radius: 5px;
  flex-shrink: 0;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 61, 130, 0.1);
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); */
  /* border: 1px solid rgba(0, 61, 130, 0.3); */
}

.data_set_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  min-height: 60px;
  width: 100%;
  margin-top: 7.5px;
  border-radius: 5px;
  flex-shrink: 0;
  background-color: rgba(0, 61, 130, 0.1);
  cursor: pointer;
}

.data_set_item_hover {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  min-height: 60px;
  width: 100%;
  margin-top: 7.5px;
  border-radius: 5px;
  flex-shrink: 0;
  background-color: rgba(0, 61, 130, 0.25);
  cursor: pointer;
}

.data_set_item:hover {
  background-color: rgba(0, 61, 130, 0.15);
}

.section_divider_no_margin {
  background-color: rgba(0, 61, 130, 0.2);
  height: 1px;
  width: 100%;
  margin-top: 15px;
  /* margin-bottom: 5px; */
}

.data_set_tile_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.data_set_item_data_layout {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}

.data_set_item_data {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  /* height: 150px; */
  /* background-color: rgba(0, 0, 0, 0.1); */
  margin-top: 20px;
  border-radius: 5px;
  gap: 10px;
}

.entry_container {
  display: flex;
  align-items: center;
  width: 100%;
}

.data_set_item_text {
  display: flex;
  justify-content: start;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  user-select: none;
  text-align: start;
  /* width: 80%; */
  overflow-wrap: break-word;
}

.data_set_item_text_alligned {
  display: flex;
  justify-content: start;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  user-select: none;
  text-align: start;
  flex-grow: 1;
  padding-right: 30px;
}

.data_set_item_text_read_only {
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
  user-select: none;
}

.data_set_item_text_index {
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  user-select: none;
  min-width: 30px;
}

.data_set_item_text_alligned:hover {
  margin: 0;
  color: rgba(0, 61, 130, 1);
  user-select: none;
}

.data_set_item_text:hover {
  margin: 0;
  color: rgba(0, 61, 130, 1);
  user-select: none;
}

.rotate_180 {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}

.training_data_container {
  width: 100%;
  /* flex: 1; */

  padding-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.training_data_title {
  margin: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  user-select: none;
  text-align: start;
}

.training_data_subtitle {
  margin: 0;
  margin-top: 5px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  user-select: none;

  text-align: start;
}

.training_data_last_updated {
  margin: 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);
  user-select: none;
}

.data_set_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}

.training_data_text {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.data_set_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}

.open_data_button {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 30px;
  width: 80px; */
  box-sizing: border-box;
  padding: 6px 10px;
  /* background-color: rgba(0, 61, 130, 0.5); */
  border: 1px solid rgba(0, 61, 130, 0.5);
  border-radius: 2.5px;
  cursor: pointer;
  color: rgba(0, 61, 130, 0.8);
  font-size: 13px;
}
.open_data_button:hover {
  background-color: rgba(0, 61, 130, 1);
  color: white;
}

.data_set_add_button {
  margin: 0;
  font-size: 15px;
  color: rgba(0, 61, 130, 1);
  user-select: none;
  padding: 0px;
  text-align: end;
  cursor: pointer;
  white-space: nowrap;
}

.data_set_add_button:hover {
  color: rgba(0, 61, 130, 0.55);
}

.data_set_delete_button {
  margin: 0;
  font-size: 15px;
  color: rgba(200, 0, 0, 1);
  user-select: none;
  padding: 0px;
  margin-right: 20px;
  text-align: end;
  cursor: pointer;
  white-space: nowrap;
}

.data_set_delete_button:hover {
  color: rgba(200, 0, 0, 0.5);
}

.data_input_identity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 5px 10px;
  border-radius: 2.5px;
  /* margin-left: 10px; */
  box-sizing: border-box;
  /* border: 1px solid rgba(0, 61, 130, 0.55); */
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  min-width: 110px;
  min-height: 20px;
  height: 30px;
  user-select: none;
}

.data_input_identity:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.data_input_identity_input {
  border: none;
  outline: none;
  background-color: transparent;
  max-width: 70px;
  cursor: pointer;
  user-select: none;
}

.data_input_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  min-height: 20px;
  height: 30px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px 10px;
  border-radius: 2.5px;
  margin-left: 10px;
  /* height: 20px; */
  /* margin-right: 10px; */
  color: rgba(0, 0, 0, 0.6);
  font-weight: 200px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  /* width: 100%; */
  /* flex-grow: 1; */
  user-select: none;
}

.data_input_options {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* height: 30px; */
  /* justify-content: start; */
  /* width: 100%; */
  user-select: none;
}

.data_input_positioning {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  user-select: none;
}

.dropdown_content {
  display: flex;
  align-items: center;
  position: absolute;
  flex-direction: column;

  left: 0px;
  top: calc(100%);
  /* Starts 20px below the parent div */
  width: 100%;
  overflow-y: auto;
  background-color: rgba(15, 15, 15, 0.8);
  border: 1px solid rgba(0, 61, 130, 0.2);
  border-radius: 4px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
  z-index: 99999;
}

.dropdown_option {
  display: flex;
  align-items: center;
  text-align: start;
  height: 40px;
  width: 100%;
  color: rgba(255, 255, 255, 0.75);
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
}

.dropdown_option:hover {
  color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.1);
}

.entry_control_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2.5px;
  margin-left: 10px;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 200px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.vertical_divider {
  height: 12px;
  width: 1px;
  background-color: rgba(0, 61, 130, 0.5);
  margin-left: 10px;
  margin-right: 20px;
}
.data_set_item_text_box {
  display: flex;
  align-items: center;
  justify-content: start;
}

.check_control_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 131, 10, 0.2);
  border-radius: 5px;
  border: none;
  outline: none;
  border-radius: 2.5px;
  margin-left: 10px;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 200px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.check_control_button:hover {
  background-color: rgba(0, 131, 10, 0.35);
}

.cancel_control_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: none;
  outline: none;
  border-radius: 2.5px;
  margin-left: 10px;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 200px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.cancel_control_button:hover {
  background-color: rgba(0, 0, 0, 0.35);
}

.trash_control_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.2);
  border-radius: 5px;
  border: none;
  outline: none;
  border-radius: 2.5px;
  margin-left: 10px;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 200px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.trash_control_button:hover {
  background-color: rgba(255, 0, 0, 0.35);
}

.check_svg {
  stroke: #00830a;
  opacity: 0.75;
}

.cross_svg {
  stroke: rgba(0, 0, 0, 0.3);
  opacity: 0.8;
}

.trash_svg {
  stroke: rgba(255, 0, 0, 0.3);
  fill: rgba(255, 0, 0, 0.3);
  opacity: 0.75;
}

.header_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.entry_error {
  border: 1px solid rgba(255, 0, 0, 0.3);
}

.data_set_tile_info_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.line_row_title {
  margin: 0;
  font-size: 16px;
  /* color: rgba(0, 61, 130, 1); */
  color: rgba(0, 0, 0, 0.6);
  user-select: none;
}

.line_row_entry {
  margin: 0;
  font-size: 16px;
  /* color: rgba(0, 61, 130, 1); */
  color: rgba(0, 0, 0, 0.6);
  user-select: none;
}

.dot {
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.top_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 10px;
  min-width: 150px;
}

.ready_text {
  margin: 0;
  color: rgba(0, 131, 10, 1);
  font-size: 12px;
}

.not_ready_text {
  margin: 0;
  color: rgba(200, 0, 0, 0.8);
  font-size: 12px;
}

.red_cross_svg {
  stroke: rgba(200, 0, 0, 0.75);
}

.download_json_button {
  padding: 10px 15px;
  /* background-color: rgba(0, 61, 130, 0.55); */
  border: 1px solid rgba(0, 61, 130, 0.55);
  border-radius: 5px;
  font-size: 13px;
  color: white;
  color: rgba(0, 61, 130, 1);
  cursor: pointer;
}

.download_json_button:hover {
  background-color: rgba(0, 61, 130, 0.85);
  color: white;
}

.new_line_button {
  padding: 10px 15px;
  background-color: rgba(0, 61, 130, 1);
  border: 1px solid rgba(0, 61, 130, 0);
  /* border: 1px solid rgba(0, 61, 130, 0.55); */
  border-radius: 5px;
  font-size: 13px;
  color: white;
  cursor: pointer;
}

.new_line_button:hover {
  background-color: rgba(0, 61, 130, 0.85);
  color: white;
}

.bottom_section_divider {
  height: 1px;
  width: 100%;
  margin-top: 100px;
}

.loading_page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
}
