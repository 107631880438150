.more_button {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
  box-sizing: border-box;
}

.more_button:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

.more_dot {
  background-color: rgba(0, 0, 0, 0.5);
  width: 2.2px;
  height: 2.2px;
  border-radius: 50%;
}

.menu {
  position: absolute;
  right: 0px;
  top: 35px;
  width: 150px;
  /* height: 200px; */
  background-color: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  /* border: 1px solid black; */
  z-index: 1;
}

.menu_item {
  display: flex;
  align-items: center;
  height: 50px;
  user-select: none;
}

.menu_item:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.menu_item_text {
  color: black;
  opacity: 0.65;
  margin: 0;
  margin-left: 10px;
  user-select: none;
}

.more_layout {
  position: relative;
}
