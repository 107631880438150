.home_components {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.logo_icon {
  position: absolute;
  height: 30px;
  top: 30px;
  left: 30px;
  /* margin-bottom: 30px; */
}

.landing_image {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.home_header_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.home_header_content_right {
  position: absolute;
  display: flex;
  justify-content: end;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* .page_title {
  color: #0058dc;
  font-weight: 200;
  font-size: 30px;
  margin-bottom: 50px;
} */
.page_title {
  color: black;
  font-weight: 200;
  font-size: 32px;
  margin: 0;
  margin-bottom: 6px;
  opacity: 0.75;
}

.page_subtitle {
  color: black;
  font-weight: 200;
  font-size: 18px;
  margin: 0;
  margin-bottom: 30px;
  opacity: 0.75;
}

.sidebar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  width: 50%;
  height: 100%;

  box-sizing: border-box;
  padding: 60px 150px;
}

.right_side_sign_in {
  background-color: rgba(0, 88, 220, 1);
  flex: 1;
}

.right_side_sign_in::before {
  content: "";
  position: absolute;
  top: 0;

  right: 0;
  bottom: 0;
  width: 50%;
  background: rgba(0, 0, 0, 0); /* Dark overlay */
  backdrop-filter: blur(0px); /* Blur effect */
  z-index: 1; /* Ensure it's above the image */
}

/* .blue_background {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0; 
} */

.blue_background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview_ss {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 45%;
  height: 600px;
  object-fit: cover;
  object-position: top left;
  border-radius: 10px;
}

.sidebar_right {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 45%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    1
  ); /* Semi-transparent white background */
  border-left: 2px solid #0058dc;
  box-sizing: border-box;
  padding: 30px;
  backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
}

.sidebar_subscription {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 50%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: #0058dc;
  border-left: 2px solid white;
  box-sizing: border-box;
  padding: 30px;
  backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
}

.main_copywriting {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.two_column_inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.half_input_object {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 48%;
}

.email_input {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  /* background: rgba(0, 0, 0, 0.05); */
  margin-bottom: 15px;
  user-select: none;
  border-radius: 3px;
}

.paragraph_input {
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 70px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* background: rgba(0, 0, 0, 0.05); */
  /* margin-bottom: 15px; */
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.input {
  background-color: transparent;
  margin-left: 10px;
  flex-grow: 1;
  border: none;
  outline: none;
  margin-right: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.textarea_input {
  background-color: transparent;
  /* margin-left: 10px; */
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  /* margin-right: 20px; */
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
}

.input::placeholder {
  color: black;
  opacity: 0.2;
  font-weight: 400;
}

.dropdown_input_option {
  width: 100%;
}

.dropdown_input {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* background: rgba(0, 0, 0, 0.05); */
  margin-bottom: 15px;
}

.dropdown_input_element {
  border: none;
  outline: none;
  flex-grow: 1;
  margin-left: 15px;
}

/* Add this to your CSS file, or create a new CSS file if needed */
.dropdown_options {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid #ccc;
  width: 50%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  background-color: #fff;
}

.dropdown_item {
  display: flex;
  justify-content: start;
  /* height: 55px; */
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.dropdown_item:hover {
  background-color: rgba(0, 88, 220, 0.2);
}

.page_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  background-color: rgba(0, 88, 220, 0.8);
  color: white;

  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 3px;
}

.page_button:hover {
  background-color: rgba(0, 88, 220, 1);
}

.page_button_inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  /* background: rgba(0, 0, 0, 0.2); */
  background-color: rgba(0, 88, 220, 0.2);
  color: #0058dc;
  opacity: 0.5;
  margin-bottom: 10px;
  border-radius: 3px;
}

.join_button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.join_button_text {
  font-size: 15px;
  font-weight: 400;
}

.clickable_text {
  color: #0058dc;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .sidebar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    background-color: white;
    box-sizing: border-box;
    padding: 30px;
  }
}

.logo {
  width: 100px;
  object-fit: cover;
}

.login_input_titles {
  /* height: 60px; */
  margin: 0;
  margin-bottom: 8px;
  opacity: 0.4;
}

.error_text {
  color: red;
  margin: 0;
}

.auth_input_icon {
  margin-left: 20px;
  width: 16px;
  height: 16px;
  opacity: 0.6;
  object-fit: cover;
}

.subscription_plans {
  width: 100%;
}

.profile_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.proflie_picture_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.update_profile_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 30px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-color: rgba(0, 88, 220, 0.35); */
  border: 1px solid rgba(0, 88, 220, 0.6);
  margin-left: 30px;
  border-radius: 10px;
  font-size: 14px;
  color: rgba(0, 88, 220, 1);
  cursor: pointer;
}

.profile_edit_section {
  display: flex;
}

.profile_picture_bg {
  width: 75px;
  height: 75px;
  border-radius: 40%;
}

.profile_picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.update_profile_picture:hover {
  color: white;
  background-color: rgba(0, 88, 220, 0.8);
}

.prompt_texts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.note {
  color: rgba(0, 0, 0, 0.4);
  opacity: 0.75;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.forgot_password {
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-weight: 300;
  text-align: end;
  cursor: pointer;
  align-self: end;
  margin: 0;
  margin-bottom: 0px;
  visibility: hidden;
}

.forgot_password:hover {
  color: rgba(0, 0, 0, 0.7);
}

.page_break_line {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 10px;
  margin: 10px 0px;
}

.line_half {
  flex: 1;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.or_text {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 200;
  opacity: 0.5;
}

.auth_options_3rd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.sign_in_with_google {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  /* background: rgba(0, 0, 0, 0.2); */
  /* background-color: rgba(0, 88, 220, 0.2); */
  color: black;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  gap: 20px;
  font-size: 12px;
}

.google_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
}

.login_with_auth_text {
  font-size: 13px;
  font-weight: 300;
}
