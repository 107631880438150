.main_layout {
  display: flex;
  flex-direction: column;
  padding: 34px;
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  margin-bottom: 20px;
}

.feedbackBox {
  width: 100%;
  height: 400px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid rgba(0, 61, 130, 0.75);
  box-sizing: border-box;
  padding: 15px;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}

.send_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background-color: #003d82;

  color: white;
  align-self: end;
  cursor: pointer;
  margin-left: 30px;
}

.send_button:hover {
  background-color: rgba(0, 61, 130, 0.9);
}
