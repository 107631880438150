/* .tab_layout {
  width: 100%;
  padding-top: 20px;
} */

.accountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  color: black;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.main_layout {
}

.tab_layout {
  display: flex;
  flex-direction: column;
  /* padding: 24px; */
  width: 100%;
  box-sizing: border-box;
}

.settingsTitle {
  margin: 0;
  color: black;
  font-weight: lighter;
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
}

.section_page_title {
  margin: 0;
  color: black;
  margin-bottom: 40px;
  font-weight: 200;
  font-size: 45px;
  opacity: 0.6;
}

.tabs {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid rgba(44, 78, 133, 0.2);
  min-height: 60px;
}
/* background: rgba(255, 255, 255, 0.15); */

.tab {
  padding: 10px 15px;
  font-size: 1.2em;
  font-weight: 300;
  color: black;
  transition: color 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
}

.tab:not(:last-child) {
  margin-right: 15px;
}

.tab:hover {
  color: rgba(44, 78, 133, 1);
}

.active {
  color: rgba(44, 78, 133, 1);
}

.underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  background: rgba(44, 78, 133, 1);
  transition: left 0.3s ease, width 0.3s ease;
}

.section {
  /* height: 300px; */
  border-bottom: 1px solid rgba(44, 78, 133, 0.2);
  box-sizing: border-box;
  padding-bottom: 30px;
}

.section_title {
  text-align: start;
  font-size: 25px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.profile_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.proflie_picture_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.update_profile_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 30px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-color: rgba(0, 88, 220, 0.35); */
  border: 1px solid var(--primary-color);
  margin-left: 30px;
  border-radius: 5px;
  font-size: 14px;
  color: var(--primary-color);
  cursor: pointer;
}

.profile_edit_section {
  display: flex;
}

.profile_picture_bg {
  width: 75px;
  height: 75px;
  border-radius: 40%;
}

.profile_picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.update_profile_picture:hover {
  color: white;
  background-color: var(--primary-color);
}

.profile_picture_section_left {
  width: 49%;
}
.profile_picture_section_right {
  width: 49%;
}
.single_line_input {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
}

.single_line_input_editable {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  border-radius: 5px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
}

.input_title {
  margin: 0;
  text-align: start;
  font-size: 16px;
  margin-bottom: 10px;
}

.profile_top_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}

.edit_profile_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background-color: var(--primary-color);
  margin-top: 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.edit_profile_button:hover {
  background-color: white;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.save_profile_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background-color: rgba(44, 78, 133, 1);
  margin-top: 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.save_profile_button:hover {
  background-color: rgba(44, 78, 133, 0.75);
}

.manage_subscription_button {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  background-color: rgba(44, 78, 133, 1);
  border: 1px solid rgba(44, 78, 133, 0);
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.manage_subscription_button:hover {
  background-color: white;
  color: rgba(44, 78, 133, 1);
  border: 1px solid rgba(44, 78, 133, 1);
}

.plan_title {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 12.5px;
}

.plan_price {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 12.5px;
}

.plan_renew {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
}

/* CREDITCARD */
.credit_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 330px;
  height: 180px;
  /* background-image: linear-gradient(
    to right,
    rgba(65, 169, 246, 1),
    rgba(25, 118, 210, 0.8),
    rgba(13, 71, 161, 1)
  ); */

  background-image: linear-gradient(
    to right,
    rgba(13, 71, 161, 1),
    rgba(25, 118, 210, 0.8),
    rgba(65, 169, 246, 1)
  );
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}

.card_top_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  margin-bottom: 15px;
  /* height: 40px; */
}

.chip_image {
  height: 20px;
  object-fit: cover;
}

.card_brand_image {
  height: 25px;
  width: 60px;
  object-fit: cover;
}

.card_default {
  width: 70px;
  /* height: 40px; */
}

.card_middle {
  display: flex;
  width: 100%;
  height: 30px;
}

.card_number {
  margin: 0;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 18px;
}

.card_bottom_info {
  display: flex;
  align-items: row;
  justify-content: space-between;
  width: 100%;
}

.card_info_left {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.card_title {
  margin: 0;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  margin-bottom: 3px;
}

.card_value {
  margin: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
}

.card_info_right {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.payment_section {
  display: flex;
  flex-direction: column;
  align-items: start;
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  box-sizing: border-box;
  padding-bottom: 30px;
  width: 100%;
}

.payment_plan_info {
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;
}

.payment_section_button {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  box-sizing: border-box;
  padding-bottom: 30px;
  width: 100%;
}
