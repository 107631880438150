.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999999;
}

.modalPaper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  width: 400px;
  /* min-height: 300px; */
  overflow-y: auto;
  border-radius: 8px;
  padding: 20px;
}

.delete_icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.modal_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
}

.counterweight {
}

.close_icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.close_icon:hover {
  opacity: 0.75;
}

.popup_text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  opacity: 0.7;
  height: 35px;
}

.modal_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.popup_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.cancel_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  border: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.cancel_button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.delete_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  border: 5px;
  background-color: rgba(243, 201, 60, 1);
  border-radius: 5px;
  cursor: pointer;
  color: white;
  border: 1px solid rgba(243, 201, 60, 0);
}

.delete_button:hover {
  background-color: rgba(243, 201, 60, 0.3);
  color: rgba(243, 201, 60, 1);
  border: 1px solid rgba(243, 201, 60, 0.5);
}

.input_option {
  width: 100%;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.09);
  margin-bottom: 10px;
}
