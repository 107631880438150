.notifications_sidebar {
  display: flex;
  flex-direction: column;
  /* width: 250px; */
  /* height: calc(100vh - 80px); */
  width: 100%;
  height: 100%;

  /* z-index: 9999; */
}

.notifications_sidebar_text {
  color: rgba(0, 61, 130, 1);
  font-size: 25px;
  font-weight: 300;
  margin: 0;
  user-select: none;
  text-align: start;
}
.notifications_sidebar_content {
  margin-top: 10px;
  flex-grow: 1;
}

.notification_cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.02);
  height: 50px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.notification_cell_text {
  display: flex;
  flex-direction: column;
  margin-left: 7.5px;
}

.notif_image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 7.5px;
}

.notification_text {
  margin: 0;
  color: white;
  font-size: 12px;
}

.notification_time {
  margin: 0;
  color: white;
  font-size: 12px;
  opacity: 0.6;
}

.notification_image {
  margin: 0;
  color: white;
  font-size: 10px;
}

.green_word {
  margin: 0;
  color: #2ebb55;
  font-size: 10px;
}
