.root {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  width: 100%;
  background-color: silver;
  height: 100vh;
  position: fixed;
  top: 0;
}

.drawer {
  width: 250px;
  border: none !important;
}

.drawerPaper {
  border: none !important;
  width: 250px;
  background: white !important;
  overflow-y: auto !important;
  height: 100vh;
  /* border-right: 1px solid rgba(0, 0, 0, 0.5) !important; */
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3) !important;
  z-index: 99999 !important;
}

.content {
  margin-top: 60px;
  /* flex-grow: 1; */
  background-color: white;
  margin-left: 250px;
  height: calc(100vh - 60px);
}

.container {
  max-width: calc(100% - 250px) !important;
}
.iconWrapper {
  display: flex;
  gap: 10px;
}
.iconWrapper svg {
  cursor: pointer;
}
.iconWrapper svg:hover path {
  fill: white !important;
}
@media (min-width: 1800px) {
  .container {
    max-width: calc(100% - 250px) !important;
  }
}

@media (max-width: 600px) {
  .content {
    padding: 8px !important;
  }
  .container {
    padding: 8px !important;
  }
}

.sidebar_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
}

.sidebar_icon_image {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.listItemText {
  font-size: 16px;
  font-family: "Poppins";
  color: white;
}

.sidebar_bottom_layout {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.sidebar_bottom_options {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* background-color: #2EBA55; */
}

/* ######### */
.navbar_sidebar_extension {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  width: 100%;
  height: 60px;
}

.sidebar_logo {
  height: 60%;
  object-fit: cover;
  user-select: none;
}

.sidebar_tab_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55px;
  user-select: none;
  cursor: pointer;
  /* margin-bottom: 10px; */
}

.sidebar_tab_item_disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55px;
  user-select: none;
  opacity: 0.5;
  pointer-events: none;
}

.active_list_item_content {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 80%;
  background-color: white;
  height: 100%;
  color: rgba(255, 255, 255, 0.5); /* Set default text color */

  user-select: none;
}

.list_item_content {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  /* border-radius: 5px; */
  color: rgba(255, 255, 255, 0.5); /* Set default text color */
  user-select: none;
  cursor: pointer;
}

.list_item_content:hover,
.list_item_content:hover .active_list_item_content,
.active_list_item_content {
  background-color: var(--sidebar-item-background-hovered);
  color: var(--sidebar-text-color-selected);
  user-select: none;
}

.active_sidebar_text {
  user-select: none;
  /* color: rgba(0, 61, 130, 1); */
  color: var(--sidebar-text-color-selected);
  margin: 0;
  margin-left: 15px;
}

.hovered_sidebar_text {
  user-select: none;
  /* color: #0058dc; */
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
  margin-left: 15px;
}

.sidebar_text {
  /* color: white; */
  color: black;
  margin: var(--sidebar-text-color-selected);
  margin-left: 15px;
}

.share_refferal_promotion {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 70px;
  background-color: #0058dc;
  margin-bottom: 25px;
  border-radius: 7.5px;
}

.developer_mode_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  border-radius: 7.5px;
  color: white;
  opacity: 0.8;
  cursor: pointer;
}

.developer_mode_button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  cursor: pointer;
}

.share_refferal_title {
  color: white;
  font-size: 18px;
  margin: 0;
}

.share_refferal_subtitle {
  color: white;
  font-size: 13px;
  margin: 0;
}

.social_icon {
  object-fit: cover;
  width: 25px;
  height: 25px;
  object-fit: cover;
  opacity: 0.6;
}

.sidebar_notification {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(44, 78, 133, 1);
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  color: white;
  font-size: 12px;
}

.adminIcon {
  width: 22px; /* Adjust size */
  height: 22px; /* Adjust size */
  stroke: currentColor; /* Use color from font color */
  margin-left: 20px;
  margin-right: 20px;
}

.locked_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  color: white;
  font-size: 12px;
}
