.tab_layout {
  width: 100%;
}

.lineDivider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.15);
  margin-bottom: 20px;
}

.credit_cards_container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/* ######################################## CREDIT CARD ######################################## */

.overlayShape {
  position: absolute;
  inset: 0;
}

.overlayShapeBefore {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
  z-index: 1;
}

.creditCardContainer {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 25%;
  height: 12rem;
  border-radius: 0.375rem;
  padding: 0.75rem;
  /* background-color: #2eba55; */
  background-color: rgba(46, 186, 85, 0.025);
  /* ADD OPACITY */
  margin-right: 20px;
}

.overlayShape {
  position: absolute;
  inset: 0;
}

.cardDetailsLeft {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  z-index: 10;
}

.cardDetailsRight {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: end;
  z-index: 10;
}

.image_box {
  height: 20%;
}

.moreButton {
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}

.cardLogo {
  width: 70px;
  /* height: 20px; */
  object-fit: cover;
}

.cardInfo {
  color: #ffffff;
  height: 20%;
}

.cardInfoRight {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  height: 20%;
  align-items: end;
}

.infoTitle {
  font-weight: 500;
  margin: 0;
}

.infoValue {
  font-weight: 400;
  margin: 0;
}

.cardNumber {
  color: #ffffff;
  margin: 0;
}

/* .cardInfoTitle {
  color: #ffffff;
  margin: 0;
}

.cardInfoSubtitle {
  color: #ffffff;
  margin: 0;
} */

/* .cardDetails,
.cardDetailsRight {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 10;
} */

.logoPlaceholder {
  width: 3rem;
  height: 3rem;
  background-color: #708090;
}

.addPayment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 12rem;
  border-radius: 0.375rem;
  padding: 0.75rem;
  border: 2px solid #2eba55;
}

.addPaymentIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #2eba55;
  color: #ffffff;
  border-radius: 50%;
}

.icon {
  height: 32px;
  width: 32px;
}

.addPaymentText {
  margin-top: 8px;
  color: #2eba55;
}

/* BILLING HISTORY */

.container {
  margin: 2.5rem auto;
  width: 100%;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.tableContainer {
  overflow: hidden;
  /* border-bottom: 1px solid #e2e8f0; */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.tableHeader {
  background-color: rgba(255, 255, 255, 0.15);
}

.tableHeading {
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.tableBody {
  background-color: rgba(0, 0, 0, 0.5);
  border-collapse: separate;
  border-spacing: 0;
}

.tableData {
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.875rem;
  color: #6b7280;
  white-space: nowrap;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  align-items: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  width: 70px;
  height: 45px;
  border-radius: 5px;
}

.prevBtn {
  margin-right: 0.5rem;
}

.nextBtn {
  margin-left: 0.5rem;
}

/* //esfes */

/* #####################################   -   NOTIFICATIONS TAB --- ##################################### */

.notifications_menu {
  width: 100%;
}

.notification_item {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 20px;
  /* justify-content: center; */
}

.notification_title {
  color: white;
  font-size: 20px;
  font-weight: lighter;
  margin: 0;
  padding: 0;
}

.notification_subtitle {
  color: white;
  font-size: 17px;
  font-weight: lighter;
  margin: 0;
}

.notifications_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-end; */
  width: 100%;
}

.notifications_group_left {
  width: 40%;
}

.notifications_group_right {
  width: 40%;
}

.notification_item_text {
  width: 70%;
}

.switchBox {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
  margin-right: 10px;
  margin-top: 5px;
}

.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: background-color 0.3s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  transition: transform 0.3s;
}

.checkbox:checked + .slider {
  background-color: #2eba55;
}

.checkbox:checked + .slider::before {
  background: white;
  transform: translateX(32px);
}

.account_settings_tab {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.account_setting_section_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  /* background-color: red; */

  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.button_area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  height: 45px;
}

.button_full {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.button_half {
  background-color: rgba(0, 0, 0, 0.1);
  width: 48%;
  height: 100%;
  border-radius: 5px;
}

.account_info_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.section_title {
  margin: 0;
  color: black;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  opacity: 0.6;
}

.section_page_title {
  margin: 0;
  color: black;
  margin-bottom: 20px;
  font-weight: 200;
  font-size: 25px;
  opacity: 0.6;
}

.input_area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 55%;
  height: 45px;
}

.text_input_full {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.text_input_half {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 48%;
  height: 100%;
  border-radius: 5px;
}

.pfp_circle {
  background-color: rgba(0, 0, 0, 0.1);
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.spacer {
  height: 30px;
}

.profile_info_text {
  color: black;
  opacity: 0.8;
  margin: 0;
  margin-left: 15px;
}
