.main_layout {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.02);
}

.cover_image_background {
  height: 170px;
  min-height: 170px;
  width: 100%;
  position: relative;
}
.cover_image_shadow_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.2); */
  z-index: 99;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover_gradient {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: linear-gradient(
    to top,
    var(--profile-gradient-color),
    var(--profile-gradient-color-light)
  );
}

.profile_banner {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75px;
  min-height: 75px;
  object-fit: cover;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  z-index: 9999;
  box-sizing: border-box;
  padding: 0 50px;
}

.profile_pic {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  margin-top: -55px;
  z-index: 9999;
}

.profile_pic_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile_info_section {
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 15px;
}

.profile_info_title {
  color: black;
  margin: 0;
  font-size: 20px;
  font-weight: 300;
  opacity: 1;
  margin-bottom: 1.5px;
  text-align: start;
}

.profile_info_subtitle {
  color: black;
  margin: 0;
  font-size: 16px;
  font-weight: 200;
  opacity: 0.75;
  margin-bottom: 1.5px;
  text-align: start;
}

.profile_info {
  display: flex;
  flex-direction: column;
}

.edit_profile_button {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--profile-gradient-color);
  font-size: 14px;
  font-weight: 400;
  color: var(--profile-gradient-color);
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

.edit_profile_button:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}
