.tab_layout {
  width: 100%;
}

.lineDivider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.15);
  margin-bottom: 20px;
}

.credit_cards_container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/* ######################################## CREDIT CARD ######################################## */

.overlayShape {
  position: absolute;
  inset: 0;
}

.overlayShapeBefore {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
  z-index: 1;
}

.creditCardContainer {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 25%;
  height: 12rem;
  border-radius: 0.375rem;
  padding: 0.75rem;
  /* background-color: #2eba55; */
  background-color: rgba(46, 186, 85, 0.025);
  /* ADD OPACITY */
  margin-right: 20px;
}

.overlayShape {
  position: absolute;
  inset: 0;
}

.cardDetailsLeft {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  z-index: 10;
}

.cardDetailsRight {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: end;
  z-index: 10;
}

.image_box {
  height: 20%;
}

.moreButton {
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}

.cardLogo {
  width: 70px;
  /* height: 20px; */
  object-fit: cover;
}

.cardInfo {
  color: #ffffff;
  height: 20%;
}

.cardInfoRight {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  height: 20%;
  align-items: end;
}

.infoTitle {
  font-weight: 500;
  margin: 0;
}

.infoValue {
  font-weight: 400;
  margin: 0;
}

.cardNumber {
  color: #ffffff;
  margin: 0;
}

/* .cardInfoTitle {
  color: #ffffff;
  margin: 0;
}

.cardInfoSubtitle {
  color: #ffffff;
  margin: 0;
} */

/* .cardDetails,
.cardDetailsRight {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 10;
} */

.logoPlaceholder {
  width: 3rem;
  height: 3rem;
  background-color: #708090;
}

.addPayment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 12rem;
  border-radius: 0.375rem;
  padding: 0.75rem;
  border: 2px solid #2eba55;
}

.addPaymentIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #2eba55;
  color: #ffffff;
  border-radius: 50%;
}

.icon {
  height: 32px;
  width: 32px;
}

.addPaymentText {
  margin-top: 8px;
  color: #2eba55;
}

/* BILLING HISTORY */

.container {
  margin: 2.5rem auto;
  width: 100%;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.tableContainer {
  overflow: hidden;
  /* border-bottom: 1px solid #e2e8f0; */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.tableHeader {
  background-color: rgba(255, 255, 255, 0.15);
}

.tableHeading {
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.tableBody {
  background-color: rgba(0, 0, 0, 0.5);
  border-collapse: separate;
  border-spacing: 0;
}

.tableData {
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.875rem;
  color: #6b7280;
  white-space: nowrap;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  align-items: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  width: 70px;
  height: 45px;
  border-radius: 5px;
}

.prevBtn {
  margin-right: 0.5rem;
}

.nextBtn {
  margin-left: 0.5rem;
}

/* //esfes */

/* #####################################   -   NOTIFICATIONS TAB --- ##################################### */

.notifications_menu {
  width: 100%;
}

.notification_item {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 20px;
  /* justify-content: center; */
}

.notification_title {
  color: white;
  font-size: 20px;
  font-weight: lighter;
  margin: 0;
  padding: 0;
}

.notification_subtitle {
  color: white;
  font-size: 17px;
  font-weight: lighter;
  margin: 0;
}

.notifications_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-end; */
  width: 100%;
}

.notifications_group_left {
  width: 40%;
}

.notifications_group_right {
  width: 40%;
}

.notification_item_text {
  width: 70%;
}

.switchBox {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
  margin-right: 10px;
  margin-top: 5px;
}

.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: background-color 0.3s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  transition: transform 0.3s;
}

.checkbox:checked + .slider {
  background-color: #2eba55;
}

.checkbox:checked + .slider::before {
  background: white;
  transform: translateX(32px);
}

.account_settings_tab {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.account_setting_section_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  /* background-color: red; */

  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.button_area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  height: 45px;
}

.button_full {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.button_half {
  background-color: rgba(0, 0, 0, 0.1);
  width: 48%;
  height: 100%;
  border-radius: 5px;
}

.account_info_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.section_title {
  margin: 0;
  color: black;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  opacity: 0.6;
}

.input_area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 55%;
  height: 45px;
}

.text_input_full {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.text_input_half {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 48%;
  height: 100%;
  border-radius: 5px;
}

.pfp_circle {
  background-color: rgba(0, 0, 0, 0.1);
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.spacer {
  height: 30px;
}

.profile_info_text {
  color: black;
  opacity: 0.8;
  margin: 0;
  margin-left: 15px;
}

/* .tab_layout {
  width: 100%;
  padding-top: 20px;
} */

.accountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  color: black;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.main_layout {
}

.tab_layout {
  display: flex;
  flex-direction: column;
  /* padding: 24px; */
  width: 100%;
  box-sizing: border-box;
}

.settingsTitle {
  margin: 0;
  color: black;
  font-weight: lighter;
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  min-height: 60px;
}
/* background: rgba(255, 255, 255, 0.15); */

.tab {
  padding: 10px 15px;
  font-size: 22px;
  font-weight: 300;
  color: black;
  transition: color 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
}

.tab:not(:last-child) {
  margin-right: 15px;
}

.tab:hover {
  color: rgba(0, 88, 220, 0.5);
}

.active {
  color: rgba(0, 88, 220, 1);
}

.underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  background: rgba(0, 88, 220, 1);
  transition: left 0.3s ease, width 0.3s ease;
}

.section {
  /* height: 300px; */
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  box-sizing: border-box;
  padding-bottom: 30px;
}

.section_title {
  text-align: start;
  font-size: 25px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.profile_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.proflie_picture_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.update_profile_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 30px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-color: rgba(0, 88, 220, 0.35); */
  border: 1px solid rgba(0, 88, 220, 1);
  margin-left: 30px;
  border-radius: 10px;
  font-size: 14px;
  color: rgba(0, 88, 220, 1);
  cursor: pointer;
}

.profile_edit_section {
  display: flex;
  /* margin-bottom: 75px; */
}

.profile_picture_bg {
  width: 75px;
  height: 75px;
  border-radius: 40%;
}

.profile_picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.update_profile_picture:hover {
  color: white;
  background-color: rgba(0, 88, 220, 0.8);
}

.profile_picture_section_left {
  width: 49%;
}
.profile_picture_section_right {
  width: 49%;
}
.single_line_input {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
}

.single_line_input_editable {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  border-radius: 5px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
}

.input_title {
  margin: 0;
  text-align: start;
  font-size: 16px;
  margin-bottom: 10px;
}

.profile_top_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}

.edit_profile_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background-color: rgba(0, 88, 220, 1);
  margin-top: 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.edit_profile_button:hover {
  background-color: white;
  border: 1px solid rgba(0, 88, 220, 1);
  color: rgba(0, 88, 220, 1);
}

.save_profile_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background-color: rgba(0, 88, 220, 1);
  margin-top: 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.save_profile_button:hover {
  background-color: rgba(0, 88, 220, 0.75);
}

.manage_subscription_button {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  background-color: rgba(0, 88, 220, 1);
  border: 1px solid rgba(0, 88, 220, 0);
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.manage_subscription_button:hover {
  background-color: white;
  color: rgba(0, 88, 220, 1);
  border: 1px solid rgba(0, 88, 220, 1);
}

.plan_title {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 12.5px;
}

.plan_price {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 12.5px;
}

.plan_renew {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
}

/* CREDITCARD */
.credit_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 330px;
  height: 180px;
  /* background-image: linear-gradient(
    to right,
    rgba(65, 169, 246, 1),
    rgba(25, 118, 210, 0.8),
    rgba(13, 71, 161, 1)
  ); */

  background-image: linear-gradient(
    to right,
    rgba(13, 71, 161, 1),
    rgba(25, 118, 210, 0.8),
    rgba(65, 169, 246, 1)
  );
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}

.card_top_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  margin-bottom: 15px;
  /* height: 40px; */
}

.chip_image {
  height: 20px;
  object-fit: cover;
}

.card_brand_image {
  height: 25px;
  width: 60px;
  object-fit: cover;
}

.card_default {
  width: 70px;
  /* height: 40px; */
}

.card_middle {
  display: flex;
  width: 100%;
  height: 30px;
}

.card_number {
  margin: 0;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 18px;
}

.card_bottom_info {
  display: flex;
  align-items: row;
  justify-content: space-between;
  width: 100%;
}

.card_info_left {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.card_title {
  margin: 0;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  margin-bottom: 3px;
}

.card_value {
  margin: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
}

.card_info_right {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.payment_section {
  display: flex;
  flex-direction: column;
  align-items: start;
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  box-sizing: border-box;
  padding-bottom: 30px;
  width: 100%;
}

.payment_plan_info {
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;
}

.payment_section_button {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 88, 220, 0.2);
  box-sizing: border-box;
  padding-bottom: 30px;
  width: 100%;
}

.subscription_options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subscription_option_current {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 350px;
  height: 170px;
  background-color: rgba(0, 88, 220, 0.6);
  margin-right: 30px;
  border-radius: 5px;
  border: 1px solid rgba(0, 88, 220, 1);
  box-sizing: border-box;
  padding: 20px;
}

.check_icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  transform: translate(50%, -50%);
  border-radius: 50%;
  background-color: rgba(0, 88, 220, 1);
  box-sizing: border-box;
  padding: 4px;
}

.subscription_option_upgrade {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  height: 170px;
  background-color: rgba(170, 113, 190, 0.75);
  margin-right: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px;
}

.subscription_option_downgrade {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  height: 170px;
  background-color: rgba(0, 88, 220, 0.2);
  margin-right: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px;
}

.subscription_option_top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
}

.plan_type {
  margin: 0;
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.price_point {
  margin: 0;
  color: white;
}

.subscription_option_middle {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.plan_renews {
  margin: 0;
  color: white;
}

.subscription_option_bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: end;
}

.current_plan_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  border-radius: 5px;

  /* background-color: rgba(255, 255, 255, 0.75);
  color: rgba(0, 88, 220, 1); */

  background-color: rgba(0, 88, 220, 1);
  color: white;
}

.upgrade_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  border: 1px solid rgba(170, 113, 190, 1);
  border-radius: 5px;

  background-color: white;
  color: rgba(170, 113, 190, 1);
  cursor: pointer;
}

.upgrade_button:hover {
  background-color: rgba(0, 88, 220, 1);
  color: white;
}

.learn_more {
  margin: 0;
  text-decoration: underline;
  color: white;
  cursor: pointer;
}

.bolder {
  font-weight: 600;
}
