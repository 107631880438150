.main_layout {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 75px;
  padding-top: 55px;
  /* height: 100%; */
  /* width: 100%; */
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
}

.page_title_margin {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  margin-bottom: 20px;
}

.divider {
  background-color: rgba(0, 61, 130, 0.2);
  height: 1px;
  width: 100%;
  margin-top: 15px;
}

.edit_guidelinees_modal {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  width: 100%;
  overflow-x: visible;
}

.guideline_layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  padding-bottom: 10px;
}

.modal_footer {
  display: flex;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  /* margin-top: 30px; */
}

.cancel_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.65);
  margin-right: 15px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.cancel_button:hover {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.8);
  color: rgba(0, 0, 0, 0.8);
}

.new_version {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 170px;
  margin-right: 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(0, 61, 130, 0);
  background-color: rgba(0, 61, 130, 1);
  color: white;
}

.new_version:hover {
  background-color: white;
  color: rgba(0, 61, 130, 1);
  border: 1px solid rgba(0, 61, 130, 1);
}

.save_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: var(--light-background);
}

.save_button:hover {
  background-color: var(--primary-color-hover);
}

.save_button_disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: var(--light-background);
  opacity: 0.35;
}

.guideline_controls {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.1); */
  height: 40px;
  margin-bottom: 10px;
  flex: 0 0 auto;
}

.guidline_modal_top {
  display: flex;
  flex-direction: column;
}

.guideline_search {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  height: 45px;
  flex-grow: 1;
  margin-right: 10px;
  border-radius: 5px;
}

.search_icon {
  width: 25px;
  height: 25px;
  opacity: 0.5;
  margin-left: 15px;
}

.search_divider {
  width: 1px;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  opacity: 0.75;
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.search_input {
  color: black;
  opacity: 0.5;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 15px;
}

.guideline_add {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 61, 130, 1);
  width: 45px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}

.add_guideline {
  width: 20px;
  height: 20px;
  opacity: 1;
}

.guideline_add:hover {
  background-color: rgba(0, 61, 130, 0.75);
}

.global_guideline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.15); */
  background-color: rgba(0, 61, 130, 1);
  min-height: 90px;
  flex-shrink: 0;
  width: 100%;
  margin-top: 15px;
  border-radius: 5px;
  flex: 0 0 auto;
}

.global_title {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.global_edit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 60px;
  /* background-color: rgba(0, 0, 0, 0.1); */
  background-color: white;
  color: rgba(0, 61, 130, 1);
  border-radius: 2.5px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0);
}

.global_edit_button:hover {
  background-color: transparent;
  color: rgba(0, 61, 130, 1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 1);
}

.spacer {
  min-height: 125px;
}

.title {
  margin: 0;
  font-size: 16px;
  color: rgba(0, 61, 130, 1);
}

.edit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 60px;
  /* background-color: rgba(0, 61, 130, 1); */
  border: 1px solid rgba(0, 61, 130, 1);
  color: rgba(0, 61, 130, 1);
  border-radius: 2.5px;
  cursor: pointer;
}
.edit_button:hover {
  background-color: rgba(0, 61, 130, 1);
  color: white;
}

.edit_guideline_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 100%; */
}

.back_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.back_button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  height: 45px;
  width: 100px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  color: white;
  box-sizing: border-box;
}

.back_button:hover {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.8);
  color: rgba(0, 0, 0, 0.8);
}

.temp_controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
  border: 1px solid rgba(0, 61, 130, 1);
  height: 45px;
  width: 50%;
  border-radius: 5px;
  user-select: none;
}

.version_title_info {
  padding-left: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 55px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid rgba(0, 61, 130, 1);
  box-sizing: border-box;
}

.version_title_word {
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;

  font-weight: 500;
  margin-right: 5px;
}

.version_title {
  background-color: transparent;
  flex-grow: 1;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: lighter;
  color: rgba(0, 61, 130, 1);
}

.edit_guidline_info {
  padding-left: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid rgba(0, 61, 130, 1);
  box-sizing: border-box;
}

.edit_guideline_text {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.edit_guidline {
  display: flex;
  align-items: start;
  justify-content: start;
  width: 100%;
  flex-grow: 1;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 61, 130, 1);
  box-sizing: border-box;
  padding: 15px;
}

.edit_guideline_text_area {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
  /* padding-top: 10px;
  padding-left: 10px; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  overflow-y: auto;
}

.back_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.back_text {
}

.global_guidelines_dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.1); */

  border: 1px solid rgba(0, 61, 130, 1);
  height: 45px;
  flex-grow: 1;
  /* width: 50%; */
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  padding-left: 10px;
  padding-right: 12px;
  box-sizing: border-box;
}

.global_guidelines_dropdown:hover {
  background-color: rgba(0, 61, 130, 0.1);
}

.dropdown_menu {
  position: absolute;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  top: 110%; /* Adjusted from 0 to 100% to appear below the container */
  left: 0;
  width: 100%; /* Optionally set width to match the parent div */
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1); /* Optional: added shadow for better visibility */
  max-height: 250px;
  overflow-y: auto;
}

.dropdown_menu_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 50px;

  border-bottom: 1px solid rgba(0, 61, 130, 0.1);
}

.dropdown_menu_item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown_menu li {
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
}

.dropdown_menu li:hover {
  background-color: #f0f0f0;
}

.dropdown_icon {
  width: 20px;
  height: 20px;
  user-select: none;
}

.set_as_live {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  border: 1px solid rgba(0, 61, 130, 1);
  height: 45px;
  width: 150px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  padding-left: 10px;
  padding-right: 12px;
  box-sizing: border-box;
}

.last_updated {
  width: 500px;
  margin: 0;
  text-align: start;
  font-size: 12px;
  opacity: 0.7;
  align-items: start;
}

.dropdown_menu {
  position: absolute;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  top: 110%;
  left: 0;
  width: calc(100%);
  box-shadow: 0px 0px 5px rgba(164, 155, 155, 0.5);
  /* background-color: rgba(0, 61, 130, 1); */
  max-height: 350px;
  overflow-y: auto;

  border: 1px solid rgba(0, 61, 130, 0.5);
}

.dropdown_menu_item {
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 50px;
  box-sizing: border-box;
  padding-left: 15px;
  border-bottom: 1px solid rgba(0, 61, 130, 0.1);
  color: rgba(0, 61, 130, 1);
}

.dropdown_menu_item:hover {
  background-color: rgba(0, 61, 130, 0.1);
}

.dropdown_menu li {
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
}

.dropdown_menu li:hover {
  background-color: #f0f0f0;
}

.fine_tune_model {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  background-color: white;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); */
  min-height: 225px;
  width: 100%;
  /* margin-top: 15px; */
  border-radius: 5px;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(0, 61, 130, 0.2);
  /* border: 1px solid rgba(0, 61, 130, 1); */
}

.model_information_top {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.model_information_bottom {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  width: 100%;
}

.model_information {
  display: flex;
  flex-direction: row;
  flex: 1;
  /* gap: 150px; */
}

.model_info_section {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 90%;
}

.parmater_info_section {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--primary-color-hover); */
  padding: 10px 25px;
  border-radius: 30px;
  color: rgba(200, 0, 0, 1);
  border: 1px solid rgba(200, 0, 0, 1);
  cursor: pointer;
  font-size: 14px;
}

.delete:hover {
  background-color: rgba(200, 0, 0, 0.1);
  color: rgba(200, 0, 0, 1);
}

.learn_more {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: var(--primary-quarter-color); */
  width: 150px;
  box-sizing: border-box;
  padding: 10px 10px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  cursor: pointer;
  font-size: 14px;
}

.learn_more:hover {
  opacity: 0.75;
}

.training_data_disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px 15px;
  border-radius: 2.5px;
  color: rgba(0, 61, 130, 0.5);
  font-size: 14px;
  opacity: 0.75;
}

.model_section_title {
  margin: 0;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
}

.model_section_text {
  margin: 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 7.5px;
}

.model_description_text {
  margin: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 7.5px;
}

.model_created_at {
  margin: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
}

.view_information_buttons {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.form_field_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* margin-top: 25px; */
  width: 100%;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin-bottom: 35px;
}

.section_divider {
  background-color: rgba(0, 61, 130, 0.2);
  height: 1px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.form_field_container_margin {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 15px;
  width: 100%;
}

.title_area_create {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.back_button_small {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  color: white;
  box-sizing: border-box;
  margin-right: 15px;
}

.back_button_small:hover {
  background-color: rgba(0, 0, 0, 0.05);
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
}

.back_icon_opacity {
  width: 20px;
  height: 20px;
  object-fit: cover;
  opacity: 0.7;
}

.status_box_success {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 131, 10, 0.05);
  padding: 10px 15px;
  color: #00830a;
  border-radius: 5px;
  font-size: 14px;
}

.status_box_featured {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color-hover);
  padding: 10px 15px;
  color: var(--primary-color);
  border-radius: 5px;
  font-size: 14px;
}

.status_box_failed {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(200, 0, 0, 0.05);
  padding: 10px 15px;
  color: rgba(200, 0, 0, 0.75);
  border-radius: 5px;
  font-size: 14px;
}

.status_box_building {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 131, 10, 0.05);
  padding: 10px 15px;

  color: #00830a;
  border-radius: 5px;
  font-size: 14px;

  gap: 7.5px;
}

.bottom_section_divider {
  height: 1px;
  width: 100%;
  margin-top: 50px;
}

.document_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 270px;
  border-radius: 10px;
  margin-top: 20px;
}

.drag_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px solid rgba(46, 187, 85, 0.2);
  border-radius: 10px;
}

.audioDropzone {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.drag_track_image {
  margin-top: 30px;
  width: 50px;
  height: 50px;
  opacity: 0.2;
}

.drag_track_text {
  color: white;
  opacity: 0.3;
  margin: 0;
  margin-top: 7.5px;
}

.drag_track_text_browse {
  color: rgba(46, 187, 85, 1);
  margin: 0;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.drag_track_text_browse:hover {
  color: white;
}

.chosen_document {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}

.full_section {
  width: 100%;
}

.program_title {
  margin: 0;
  font-size: 22px;
  color: rgba(0, 0, 0, 1);
}

.program_type_title {
  margin: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 3.5px;
}

.program_description_text {
  margin: 0;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 9px;
  width: 100%;
}

.trash_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(200, 0, 0, 0.2);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.pinned_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.program_layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 35px;
  align-items: center;
  background-color: white;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); */
  min-height: 275px;
  width: 100%;
  /* margin-top: 15px; */
  border-radius: 5px;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(0, 61, 130, 0.2);
  /* border: 1px solid rgba(0, 61, 130, 1); */
}

.subscribe {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--primary-color-hover); */
  padding: 10px 25px;
  border-radius: 30px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.subscribe:hover {
  background-color: var(--primary-color-hover);
  color: var(--primary-color);
}

.currently_subscribed {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--primary-color-hover); */
  padding: 10px 25px;
  border-radius: 30px;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  gap: 10px;
}

.currently_subscribed:hover {

}
