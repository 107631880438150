.page_layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
  flex-grow: 1;
}

.sidebar {
  min-width: 200px;
  border: none;
  background: white;
  overflow-y: auto;
  height: 100vh;

  /* background-color: rgba(0, 61, 130, 0.1); */
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  background-color: var(--sidebar-bg-color); /* Fallback to white */
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3) !important;
  /* border-right: 1px solid rgba(0, 0, 0, 0.5); */
}

.menu_item {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  padding: 15px;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.menu_item:hover {
  background-color: var(--menu-item-hover-bg-color);
  /* color: var(--menu-item-hover-color); */
  color: rgba(0, 0, 0, 0.9);
}

.menu_item_selected {
  display: flex;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;

  height: 60px;
  background-color: var(--menu-item-selected-bg-color);
  color: var(--menu-item-selected-color);
  user-select: none;
}

.locked_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  color: white;
  font-size: 12px;
}

.menu_item_disabled {
  opacity: 0.5;
  pointer-events: none;
}
