.main_layout {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  /* width: calc(100% - 250px); */
  margin-left: auto;
}

.top_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.page_title {
  font-size: 35px;
  font-weight: 100;
  color: white;
  margin: 0;
  margin-bottom: 15px;
  margin-left: 25px;
}

.username {
  color: rgba(46, 187, 85, 1);
  text-decoration: underline;
}

.line_divider {
  height: 1px;
  background-color: rgba(46, 187, 85, 0.2);
  margin-top: 35px;
  margin-bottom: 35px;
}

.line_divider_invisible {
  height: 1px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.submit_page_header {
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  height: 175px;
  margin-bottom: 35px;
  border-radius: 5px;
}

.opportunity_info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 38%;
  height: 175px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 35px;
  border-radius: 10px;
  padding: 15px;
}

.opportunity_info_top_layout {
  display: flex;
  flex-direction: row;
}

.opportunity_info_top {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.opportunity_image {
  width: 120px;
  height: 97%;
  object-fit: cover;
  border-radius: 10px;
}

.opportunity_info_title {
  margin: 0;
  font-size: 16px;
  color: white;
  margin-left: 15px;
}

.opportunity_info_subtitle {
  margin: 0;
  font-size: 12px;
  color: white;
  margin-left: 15px;
  opacity: 0.7;
}

.opportunity_info_bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  height: 97%;
  flex-grow: 1;
}

.opportunity_specifics {
  margin: 0;
  font-size: 13px;
  color: white;
  opacity: 1;
  /* margin-left: 10px; */
}

.opportunity_time {
  margin: 0;
  font-size: 15px;
  color: rgba(46, 187, 85, 0.8);
}

.bullet {
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: rgba(46, 187, 85, 0.8);
  border-radius: 50%;
  margin-right: 8px;
}

.clock_image {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.time_stamp_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.drag_track {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 225px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  margin-bottom: 35px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba(46, 187, 85, 0.2);
}

.drag_track_loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 225px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  margin-bottom: 35px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba(46, 187, 85, 0.2);
}

.image_loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 225px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  margin-bottom: 35px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba(46, 187, 85, 0.2);
}

.drag_track_full {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 175px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 35px;
  border-radius: 10px;
  padding: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.track_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 140px;

  border-radius: 10px;
  margin-top: 20px;
}

.upload_track_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 180px;

  border-radius: 10px;
  /* margin-top: 20px; */
}

.document_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 270px;
  /* height: 140px; */

  border-radius: 10px;
  margin-top: 20px;
}

.drag_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px solid rgba(46, 187, 85, 0.2);
  border-radius: 10px;
}

.chosen_file {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 100%;
  width: 100%;
}

.chosen_document {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  width: 100%;
}

.image_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 225px;

  margin-bottom: 35px;
  border-radius: 10px;
}

.drag_track_image {
  margin-top: 30px;
  width: 50px;
  height: 50px;
  opacity: 0.2;
}

.drag_track_text {
  color: white;
  opacity: 0.3;
  margin: 0;
  margin-top: 7.5px;
}

.drag_track_button_text {
  color: white;
  margin: 0;
  cursor: pointer;
}

.account_info_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* margin-top: 30px; */
}

.account_info_container_margin {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 25px;
}

.account_info_container_single_margin {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 25px;
}

/* HELLO */
.user_info_layout {
  width: 100%;
}

.user_info_title {
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.posted_by_title {
  color: white;
  margin: 0;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0.5;
}

.input_box {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.00);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.input_box_dropdown {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.00);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.description {
  /* width: 1023px !important; */
  height: 130px !important;
}

.input_box_paragraph {
  display: flex;
  align-items: start;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.00);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px 5px;
}

.input_box_paragraph:hover {
  border: 1px solid var(--menu-item-selected-color);
}

.input_box:hover {
  border: 1px solid var(--menu-item-selected-color);
}

/* .input_box_dropdown:hover {
  border: 1px solid rgba(0, 61, 130, 0);
} */

.input_text {
  color: white;
  margin: 0;
  /* width: 70%; */
  flex-grow: 1;
  height: 70%;
  background-color: transparent !important;
  border: none;
  outline: rgba(0, 61, 130, 0.5);
  margin-left: 15px;
  /* outline: 1px solid rgba(46, 187, 85, 0.5); */
  margin-right: 15px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.input_box_text {
  color: black;
  margin: 0;
  /* width: 70%; */
  flex-grow: 1;
  height: 70%;
  background-color: transparent !important;
  border: none;
  outline: rgba(0, 61, 130, 0.5);
  margin-left: 15px;
  /* outline: 1px solid rgba(46, 187, 85, 0.5); */
  margin-right: 15px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
}

.input_box_text_area {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.input_text_area {
  color: white;
  margin: 0;
  flex-grow: 1;
  height: 80%;
  background-color: transparent !important;
  border: none;
  outline: rgba(0, 61, 130, 0.5);
  margin-left: 15px;
  margin-right: 15px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
}

.auth_input_box_text {
  color: white;
  margin: 0;
  /* width: 70%; */
  flex-grow: 1;
  height: 70%;
  background-color: transparent;
  border: none;
  outline: rgba(0, 61, 130, 0.5);
  margin-left: 15px;
  /* outline: 1px solid rgba(46, 187, 85, 0.5); */
  margin-right: 15px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.input_box_dropdown_button {
  width: 50px;
  height: 50px;
  margin-right: -1px;
  background-color: var(--menu-item-selected-color);
  /* border: 1px solid var(--menu-item-selected-color); */
  border-radius: 0px 5px 5px 0px;
}

.dropdown_green_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  border-radius: 0px 5px 5px 0px;
}

.dropdown_green_bg:hover {

}

.collaborator_text {
  color: white;
  margin: 0;
  font-size: 15px;
}

.add_collab_counter_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.add_collab_counter_box_text {
  margin: 0;
  color: white;
}

.counter_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
  opacity: 0.5;
  cursor: pointer;
}

.counter_icon:hover {
  opacity: 1;
}

.checkbox_horizontal_layout {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.collab_section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.form_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
}

/* .cancel_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1c1b1b;
    height: 100%;
    width: 49%;
  }
  
  .cancel_button:hover {
    background-color: #2c2b2b;
  } */

.cancel_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 49%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.cancel_button:hover {
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 1;
}

.button_text {
  color: white;
  font-size: 18px;
  font-weight: 200;
}

.submit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(46, 187, 85, 1); */
  height: 100%;
  width: 49%;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  border: 1px solid rgba(46, 187, 85, 0.4);
}

.submit_button button {
  border: none;
  background-color: transparent;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.submit_button:hover {
  background-color: rgba(46, 187, 85, 0.1);
}

.add_collab_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  height: 100%;
  width: 30%;
  border-radius: 5px;
}

.add_collab_button_text {
  color: white;
  height: 100%;
  margin-left: 15px;
}

.add_collab_counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-right: 15px;
}

/* //CHECKBOXES */
.checkbox_layout {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-right: 30px;
}

.custom_checkbox {
  /* Define your desired styles for the checkbox here */
  --checkbox-color: red;
  /* Set the desired color value */
}

.custom_checkbox:checked {
  --checkbox-color: green;
  /* Set the desired color value when checked */
}

.custom_checkbox + label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border: 2px solid var(--checkbox-color);
  background-color: var(--checkbox-color);
  margin-right: 8px;
}

.terms_text {
  color: white;
  font-size: 15px;
  font-style: italic;
  margin-left: 10px;
}

/* DROPDOWN MENU */
/* styles.css */
.dropdown_menu {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  /* Starts 20px below the parent div */
  width: 100%;
  /* Will take the width of the parent div */
  max-height: 190px;
  overflow-y: auto;
  background-color: white;
  /* border: 1px solid rgba(0, 61, 130, 0.2); */
  border-radius: 4px;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3);
  z-index: 99999;
}

.dropdown_green_bg {
  cursor: pointer;
  /* Indicates an interactive element */
}

.dropdown_menu_option_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  user-select: none;
  box-sizing: border-box;
  padding: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.dropdown_menu_option_box:hover {
  background-color: var(--sidebar-bg-color);
}

.field_info {
  display: flex;
  align-items: center;
  width: 100%;
}

.infoImage {
  width: 17.5px;
  height: 17.5px;
  object-fit: cover;
  margin-bottom: 5px;
  opacity: 0.8;
  margin-left: 15px;
}

.infoImage:hover {
  opacity: 1;
}

.drop_down_image {
  width: 25px;
  height: 25px;
  object-fit: cover;
  user-select: none;
}

.audioDropzone {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.display_file_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  min-width: 50px;
}

.cover_file_image {
  /* max-width: 100px; */
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.delete_icon_corner {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  transform: translate(50%, -50%);
  border-radius: 50%;
  background-color: rgba(200, 0, 0, 1);
  box-sizing: border-box;
  padding: 6px;
  z-index: 9999;
  cursor: pointer;
}

.delete_icon_corner:hover + .cover_file_image {
  border: 1px solid rgba(200, 0, 0, 1);
  opacity: 0.6;
}

.song_file_image {
  width: 50px;
  height: 50px;
}

.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  width: 200px;
  background-color: white;
  color: black;
  text-align: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip above the icon */
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);

  /* Add an arrow */
  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #ccc transparent transparent transparent;
  }
}

.required_tag {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.required {
  color: red;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 200;
}

.input_icon {
  color: white;
  /* opacity: 0.7; */
  margin-left: 15px;
}

.loader_display {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.file_display {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  /* width: 400px; */
  /* height: 100px; */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  flex-direction: column;
  box-sizing: border-box;
  padding: 25px;
  border: 1px solid rgba(200, 0, 0, 0);
}

.file_info {
  display: flex;
  flex-direction: row;
}

.file_title {
  margin: 0;
  color: white;
  font-weight: 400;
  margin-bottom: 17.5px;
  opacity: 0.75;
}

.file_subtitle {
  margin: 0;
  color: white;
  opacity: 0.7;
  font-weight: 200;
  margin-left: 5px;
}

.delete_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
}

.delete_button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.auth_loader {
  height: 50%;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}
/* 
circle {
  fill: none;
  stroke: rgba(46, 187, 85, 1);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
} */

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.chosen_referance_track {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 100%;
  width: 100%;
}

.document_display {
  position: relative;
  display: flex;
  flex-direction: center;
  align-items: center;
  /* width: 400px; */
  /* height: 100px; */
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid rgba(200, 0, 0, 0);
}

.document_file_title {
  margin: 0;
  color: white;
  font-weight: 300;
  opacity: 0.5;
  cursor: pointer;
}

.document_file_title:hover {
  color: rgba(0, 61, 130, 0.8);
}

.hovered {
  border: 1px solid rgba(200, 0, 0, 1);
  opacity: 0.6;
}

.posted_by_info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 20px;
}

.posted_by_textbox {
  margin-top: 20px;
  width: 300px;
}

.add_image {
  height: 30%;
  width: 30%;
  opacity: 0.5;
}

.posted_by_image_section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.posted_by_image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
}

.add_image_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  height: 35px;
  width: 120px;
  border-radius: 5px;
  /* background-color: rgba(0, 0, 0, 0.3); */
  color: rgba(255, 255, 255, 0.35);
  font-size: 13px;
  cursor: pointer;
}

.add_image_button:hover {
  color: rgba(0, 61, 130, 0.8);
  background-color: rgba(0, 0, 0, 0.2);
}

.posted_by {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.display_image_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.posted_by_image_cover {
  /* max-width: 100px; */
  height: 100%;
  width: 100%;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
}

.delete_icon_corner_circle {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  transform: translate(30%, -30%);
  border-radius: 50%;
  background-color: rgba(200, 0, 0, 1);
  box-sizing: border-box;
  padding: 6px;
  z-index: 9999;
  cursor: pointer;
}

.delete_icon_corner_circle:hover + .display_image_wrapper {
  border: 1px solid rgba(200, 0, 0, 1);
  opacity: 0.6;
}

.error_text {
  color: rgba(200, 0, 0, 0.7);
  margin: 0;
  margin-top: 5px;
}

.error_text_audio_file {
  width: 100%;
  color: rgba(200, 0, 0, 0.7);
  margin: 0;
  margin-top: 10px;
  text-align: left;
  margin-bottom: -10px;
}

.spacer {
  height: 40px;
}

.spacer_medium {
  height: 30px;
}

.spacer_form_field {
  height: 25px;
}

.section_title {
  color: white;
  margin: 0;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.submit_button_inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(46, 187, 85, 1); */
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 49%;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  opacity: 0.65;
  /* border: 1px solid rgba(46, 187, 85, 0.4); */
}

.submit_button_inactive button {
  border: none;
  background-color: transparent;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0.2;
}

.rotate_180 {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}
